<template>
  <perseu-card title="Detalhes da terapia">
    <template slot="title-right">
      <v-btn icon @click="close">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </template>
    <template v-if="therapy.therapist" slot="content">
      <v-card class="mb-3 rounded-10">
        <v-card-text>
          <div class="therapy-data black--text">
            <p>
              Terapeuta responsável:
              <strong>{{ therapy.therapist.name }}</strong>
            </p>
            <p class="mb-1">
              Data de realização:
              <strong>{{ therapy.createdAt | formatDate }}</strong>
            </p>
          </div>
        </v-card-text>
      </v-card>
      <v-textarea
        v-model="therapy.diagnosis.patientRecommendations"
        filled
        label="Recomendações ao paciente"
        readonly
      ></v-textarea>
      <diagnosis-pares
        v-if="hasPares"
        :pares="therapy.diagnosis.pares"
      ></diagnosis-pares>
      <div v-else>
        <p class="title-1">- Nenhum par foi adicionado ao seu diagnóstico.</p>
      </div>
    </template>
    <template slot="actions">
      <v-btn color="secondary" @click="close"> Fechar </v-btn>
    </template>
  </perseu-card>
</template>

<script>
export default {
  components: {
    "diagnosis-pares": () => import("./DiagnosisPares"),
  },
  props: {
    therapy: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasPares() {
      return this.therapy.diagnosis.pares.length > 0;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.therapy-data {
  font-size: 16px;
}
</style>
